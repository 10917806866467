/* eslint-disable no-param-reassign */
import { useEffect } from "react";

import { CSVLink } from "react-csv";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";

const prettyLink = {
  fontSize: 12,
  fontWeight: "bold",
  color: "#1A73E8",
  // color: "#ffffff",
};

function ELDownloadData({ rawData, reportType }) {
  // console.log("rawData", rawData, typeof rawData, rawData.length);
  // let index = 1;
  // rawData.forEach((element) => {
  //   element.AA = index;
  //   index += 1;
  // });

  useEffect(() => {
    if (reportType === "Admin")
      rawData.forEach((element, index) => {
        element = { "S.No.": index + 1, ...element };
        rawData[index] = element;
      });
  }, [rawData]);

  return (
    <MDBox
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      mt={reportType === "Admin" ? 1 : -1}
      mx={reportType === "Admin" ? 1 : 0}
      pr={1}
      pb={1}
    >
      <CSVLink
        data={rawData}
        uFEFF={false}
        filename={reportType === "Admin" ? "Admin_Report" : "Plant_Report"}
        style={prettyLink}
      >
        &#x21E9;&nbsp;EXPORT
      </CSVLink>
    </MDBox>
  );
}

ELDownloadData.defaultProps = {
  rawData: "",
  reportType: "",
};

ELDownloadData.propTypes = {
  rawData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  reportType: PropTypes.string,
};

export default ELDownloadData;
