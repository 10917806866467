import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import ELMobileView from "components/ELMobileView";
import ELMobileNavbar from "components/ELMobileNavbar";
import ELSecuity from "components/ELSecurity";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import useFetch from "layouts/dashboard/components/RemoteApi/useFetch";
import LoadingSpin from "layouts/dashboard/components/LoadingSpin";

import DeviceTableList from "./devicelist";

function flattenObject(customerName, list) {
  const TableData = [{}];

  list.forEach((location) => {
    location.substation.forEach((subStn) => {
      subStn.devices.forEach((dev) => {
        TableData.push({
          // Customer: customerName,
          Commisioned: dev.plant,
          Location: location.name,
          Area: subStn.name,
          // DeviceName: dev.name,
          EPC_Date: dev.epcdate,
          DeviceID: dev.devid,
          Status: Number(dev.linkstatus),
          LastActive: dev.lastactive,
          Lat: dev.latitude,
          Long: dev.longitude,
        });
      });
    });
  });

  // console.log("TableData...", TableData);

  return TableData;
}

const status = (value) => (
  <>
    {value ? (
      <MDBadge badgeContent="" color="success" indicator />
    ) : (
      <MDBadge badgeContent="" color="error" indicator />
    )}
  </>
);

// <MDBadge
// badgeContent={value ? "on" : "off"}
// color={value ? "success" : "error"}
// // color={value ? "success" : "success"}
// // variant="gradient"
// indicator
// // size="sm"
// // circular
// />

function DeviceTree() {
  ELSecuity();
  const onMobile = ELMobileView();
  const remoteTableHeader = [{}];

  const url = global.baseurl;
  const delimiter = "/";
  const filler = "null";
  const endPoint = "get_user_resource_tree";

  const usr = localStorage.getItem("currentusr");

  const resourceTreeApi = url.concat(usr, delimiter, filler, delimiter, endPoint);

  // console.log("DeviceTree...url ", resourceTreeApi);

  // GET DATA For Default Device...i.e. end device from the tree
  const { data: devList, loading, error } = useFetch(resourceTreeApi);

  if (devList)
    if (devList.length > 0) {
      // console.log("DeviceTree...devList ", devList);

      const customerName = localStorage.getItem("customer");
      const TableData = flattenObject(customerName, devList);
      const TableDataSlice = TableData.slice(1, TableData.length);

      Object.keys(TableDataSlice[0]).forEach((el) => {
        // console.log("Change Size", el);
        if (el === "Status") {
          remoteTableHeader.push({
            header: el,
            accessorKey: el,
            align: "center",
            size: 20,
            Cell: ({ cell }) => status(cell.getValue()),
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          });
        } else {
          remoteTableHeader.push({
            header: el,
            accessorKey: el,
            align: "center",
            size: 50,
            muiTableHeadCellProps: {
              align: "center",
            },
            muiTableBodyCellProps: {
              align: "center",
            },
          });
        }
      });

      const thisTableHeader = remoteTableHeader.slice(1, remoteTableHeader.length);

      return (
        // <PageLayout image={bgImage} p={10} m={10}>
        <DashboardLayout>
          {onMobile && <ELMobileNavbar />}
          <MDBox
            flex
            // variant="gradient"
            bgColor="#edfbef"
            borderRadius="lg"
            coloredShadow="info"
            p={1}
            mt={-1}
            // width="78vw"
            // height="95vh"
          >
            {loading && (
              <MDBox display="flex" bgColor="white" justifyContent="center" alignItems="center">
                <LoadingSpin />
              </MDBox>
            )}
            {error && <p>Server Error: {error.message}</p>}
            <DeviceTableList column={thisTableHeader} data={TableDataSlice} />
          </MDBox>
        </DashboardLayout>
      );
    }
  return null;
}
export default DeviceTree;
