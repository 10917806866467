// import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// import { useState } from "react";

function displayParam(val, bgcolor, txtColor) {
  return (
    <Grid item key={val}>
      <MDBox
        flex
        align="center"
        key={val}
        borderRadius="25px"
        mt={0.25}
        bgcolor={bgcolor}
        variant="gradient"
        shadow="lg"
      >
        <MDTypography
          flex
          variant="caption"
          color={txtColor}
          fontWeight="bold"
          textTransform="capitalize"
        >
          {val}
        </MDTypography>
      </MDBox>
    </Grid>
  );
}
// function DeviceInfo({ DevInfo }) {
function DeviceInfo() {
  const thisDevInfo = {
    // DeviceName: localStorage.getItem("devName"),
    DeviceID: localStorage.getItem("devID"),
    Location: localStorage.getItem("devSubStation"),
    Status: Number(localStorage.getItem("devStatus")) === 1 ? "Online" : "Offline",
    LastActive: localStorage.getItem("devTimeStamp"),
  };
  return (
    <Card sx={{ bgcolor: "#edfbef" }}>
      <MDBox display="flex" justifyContent="space-between">
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={6}>
            {Object.keys(thisDevInfo).map((key) => displayParam(key, "warning", "dark"))}
          </Grid>
          <Grid item xs={6}>
            {Object.values(thisDevInfo).map((val) => displayParam(val, "info", "dark"))}
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

// DeviceInfo.defaultProps = {
//   DevInfo: "",
// };

// DeviceInfo.propTypes = {
//   DevInfo: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
// };

export default DeviceInfo;
